<template>
    <y-modal
        ref="modal"
        size="xs"
        @close="reset"
    >
        <template slot="header">
            <div class="title">
                {{ $t('permissions.create') }}
            </div>
        </template>

        <template slot="body">
            <y-form-field
                v-model="model.organization"
                type="select"
                :options="organizations"
                name="organization"
                label-field="name_full"
                value-field="id"
            />

            <y-form-field
                v-model="model.role"
                type="select"
                name="role"
                :options="rolesOptions"
                label-field="title"
                value-field="id"
                :disabled="!model.organization || !rolesOptions.length"
            />

            <y-form-field
                v-model="model.level"
                type="select"
                :options="levelOptions"
                label-field="title"
                value-field="id"
                name="status"
                :disabled="!model.role || !levelOptions.length"
            />
        </template>

        <template slot="footer">
            <div class="ta-l w-100">
                <y-button
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
                <y-button
                    color="blue"
                    loading-on="submitting-form"
                    @click.prevent.native="submit(assign)"
                >
                    {{ $t('button.save') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>

    import FormMixin from '@/mixins/Form';

    import { YFormField, YModal } from '@deps';

    export default {
        name: 'CreateModal',

        components: {
            YFormField,
            YModal,
        },

        mixins: [FormMixin],

        props: {
            /**
             * Roles array
             */
            roles: Array,

            /**
             * Organizations array
             */
            organizations: Array,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: {
                    id: this.$route.params.id,
                },
            };
        },

        computed: {

            /**
             * Return roles options
             */
            rolesOptions() {
                if (!this.organizations || !this.model.organization) {
                    return [];
                }
                let roles = this.organizations.find((item) => item.id === this.model.organization).attached_roles_ids;
                roles = this.roles.filter((item) => roles.includes(item.id)) || [];
                return roles;
            },

            /**
             * Return level options
             */
            levelOptions() {
                if (!this.roles || !this.model.role) {
                    return [];
                }
                const item = this.roles.find((role) => role.id === this.model.role);
                return item ? item.levels : [];
            },

        },

        watch: {

            /**
             * Watch create role and clear level
             *
             * @param value
             * @param oldVal
             */
            'model.role': function (value, oldVal) { // eslint-disable-line func-names
                if (value && value !== oldVal) {
                    const role = this.roles.find((item) => item.id === value);
                    if (role) {
                        this.model.level = role.level_default_id;
                    }
                }
            },

        },

        methods: {
            /**
             * Open create modal
             */
            open() {
                this.$refs.modal.open();
            },

            /**
             * Close create modal
             */
            close() {
                this.$refs.modal.close();
                this.reset();
            },

            /**
             * Reset model
             */
            reset() {
                this.model = {
                    id: this.$route.params.id,
                };
            },

            /**
             * Assign role
             */
            assign() {
                return this.$services.Person.userPanelRoleAssign(this.model).then(() => {
                    this.$toast.success(this.$t('messages.assign.success'));
                    this.close();
                    this.$emit('refresh');
                }).catch((error) => this.handleError(error));
            },
        },

    };
</script>

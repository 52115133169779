<template>
    <y-modal
        ref="modal"
        size="xs"
        @close="reset"
    >
        <template slot="header">
            <div class="title">
                {{ $t('permissions.delete.title') }}
            </div>
        </template>

        <template slot="body">
            <p>{{ $t('permissions.delete.message') }}</p>
        </template>

        <template slot="footer">
            <div class="ta-l w-100">
                <y-button
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
                <y-button
                    color="red"
                    loading-on="submitting-form"
                    @click.prevent.native="submit(resign)"
                >
                    {{ $t('button.delete') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>

    import FormMixin from '@/mixins/Form';

    import { YModal } from '@deps';

    export default {
        name: 'DeleteModal',

        components: {
            YModal,
        },

        mixins: [FormMixin],

        props: {
            /**
             * Roles array
             */
            roles: Array,

            /**
             * Organizations array
             */
            organizations: Array,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                id: null,
            };
        },

        methods: {
            /**
             * Open delete modal
             *
             * @param id
             */
            open(id) {
                this.id = id;
                this.$refs.modal.open();
            },

            /**
             * Close delete modal
             */
            close() {
                this.$refs.modal.close();
                this.reset();
            },

            /**
             * Reset delete modal
             */
            reset() {
                this.id = null;
            },

            /**
             * Assign role
             */
            resign() {
                return this.$services.Person.userPanelRoleResign({ id: this.id }).then(() => {
                    this.$toast.success(this.$t('messages.resign.success'));
                    this.close();
                    this.$emit('refresh');
                }).catch( (error) => this.handleError(error) );
            },
        },

    };
</script>

<template>
    <y-modal
        ref="modal"
        size="xs"
        forced
        @close="$emit('cancel')"
    >
        <template slot="header">
            <div class="title">
                {{ $t('permissions.alert.title') }}
            </div>
        </template>

        <template slot="body">
            <p>{{ $t('permissions.alert.message') }}</p>
        </template>

        <template slot="footer">
            <div class="ta-l w-100">
                <y-button
                    color="red"
                    class="ml5"
                    @click.prevent.native="cancel"
                >
                    {{ $t('button.cancel') }}
                </y-button>
                <y-button
                    color="blue"
                    @click.prevent.native="save"
                >
                    {{ $t('button.save') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>

    import FormMixin from '@/mixins/Form';

    import { YModal } from '@deps';

    export default {
        name: 'ChangeAlertModal',

        components: {
            YModal,
        },

        mixins: [FormMixin],

        methods: {
            /**
             * Open modal
             */
            open() {
                this.$refs.modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.modal.close();
                this.$emit('cancel');
            },

            /**
             * Emit save
             */
            save() {
                this.$refs.modal.close();
                this.$emit('save');
            },

            /**
             * Emit save
             */
            cancel() {
                this.$refs.modal.close();
                this.$emit('cancel');
            },
        },

    };
</script>

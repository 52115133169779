<template>
    <y-modal
        ref="Modal"
        class="persons-roles-fields__settings"
        size="lg"
        forced
        :title="$t('roles.fields.settings.title')"
        @close="reset"
    >
        <div slot="body">
            <y-loading
                :active="$wait.is('fetching-data')"
                height="250px"
            >
                <y-panel
                    v-if="fetched && model"
                    :title="$t('roles.fields.settings.division')"
                >
                    <label>{{ $t('roles.fields.settings.from') }}</label>
                    <y-form-division
                        
                        limit="city"
                        :value="model.divisions_from"
                        allow-empty
                        @divs="selectedDivs"
                        @remove="removeDiv"
                    />

                    <y-form-field
                        v-model="model.divisions_to"
                        type="select"
                        :label="$t('roles.fields.settings.to')"
                        :options="options"
                    />
                    <y-alert
                        class="mb0"
                        color="blue"
                        :message="$t('roles.fields.settings.division__alert')"
                    />
                </y-panel>

                <y-panel :title="$t('roles.fields.settings.others')">
                    <y-form-field
                        v-model="model.multi_address"
                        type="checkbox"
                        :label="$t('fields.roles.multi_address')"
                    />
                    <y-form-field
                        v-model="model.multi_phone"
                        type="checkbox"
                        :label="$t('fields.roles.multi_phone')"
                    />
                    <y-form-field
                        v-model="model.has_country_code"
                        type="checkbox"
                        :label="$t('fields.roles.has_country_code')"
                    />
                    <y-form-field
                        v-model="model.phone_has_country_code"
                        type="checkbox"
                        :label="$t('fields.roles.phone_has_country_code')"
                    />
                    <y-form-field
                        v-model="model.phone_validation"
                        type="checkbox"
                        :label="$t('fields.roles.phone_validation')"
                    />
                    <y-alert
                        class="mb0"
                        color="blue"
                        :message="$t('roles.fields.settings.all_role_alert')"
                    />
                </y-panel>
            </y-loading>
        </div>

        <div
            slot="footer"
            class="ta-l w-100"
        >
            <y-button
                class="ml5"
                disabled-on="saving-modal"
                @click.prevent.native="close"
            >
                {{ $t('button.back') }}
            </y-button>

            <y-button
                class="ml5"
                color="green"
                disabled-on="saving-modal"
                @click.prevent.native="save"
            >
                {{ $t('button.save') }}
            </y-button>
        </div>
    </y-modal>
</template>

<script>
    import { YModal, YFormField, YAlert } from '@deps';
    import { YFormDivision } from '@deps/form/elements/Division';

    export default {
        name: 'FieldSettings',

        components: {
            YModal,
            YFormField,
            YFormDivision,
            YAlert,
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                fetched: false,
                model  : {
                    divisions_to  : '',
                    divisions_from: {
                        city         : [],
                        continent    : [],
                        country      : [],
                        neighbourhood: [],
                        province     : [],
                    },
                    multi_address: 0,
                },

                options: [],
                divs   : {
                    city         : [],
                    continent    : [],
                    country      : [],
                    neighbourhood: [],
                    province     : [],
                },
            };
        },

        methods: {
            /**
             * Open modal
             */
            open() {
                this.$refs.Modal.open();
                this.fetch();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
                this.reset();
                this.$emit('done');
            },

            /**
             * Reset data
             */
            reset() {
                this.model = {
                    divisions_to  : '',
                    divisions_from: {
                        city         : [],
                        continent    : [],
                        country      : [],
                        neighbourhood: [],
                        province     : [],
                    },
                    multi_address: 0,
                };
                this.fetched = false;
            },

            /**
             * Fetch Setting
             */
            fetch() {
                this.$wait.start('fetching-data');
                return this.$services.Person.profileFieldsSettingGet().then((response) => {
                    this.$set(this, 'model', response.data.results);
                    this.fetched = true;
                    this.toOptions(this.model.divisions_from);
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('fetching-data');
                });
            },

            /**
             * Save the Setting
             */
            save() {
                const params = {
                    ...this.model,
                };
                return this.$services.Person.profileFieldsSettingSave(params).then(() => {
                    this.$toast.success(this.$t('roles.fields.settings.messages.success'));
                    this.close();
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Check which item is selected
             *
             * @param divs
             */
            selectedDivs(divs) {
                this.$set(this, 'divs', divs);
                this.divisionsModel();
                this.toOptions(this.model.divisions_from);
            },

            /**
             * Remove div from divs
             */
            removeDiv() {
                this.toOptions(this.model.divisions_from);
            },

            /**
             * `to` select options
             *
             * @param divs
             */
            toOptions(divs) {
                this.options = [];
                if (divs) {
                    if (!divs.continent || divs.continent === '' || divs.continent === null) {
                        this.options.push({
                            label: this.$t('general.division.continent'),
                            value: 'continent',
                        });
                    }

                    if (!divs.country || divs.country === '' || divs.country === null) {
                        this.options.push({
                            label: this.$t('general.division.country'),
                            value: 'country',
                        });
                    }

                    if (!divs.province || divs.province === '' || divs.province === null) {
                        this.options.push({
                            label: this.$t('general.division.province'),
                            value: 'province',
                        });
                    }

                    if (!divs.city || divs.city === '' || divs.city === null) {
                        this.options.push({
                            label: this.$t('general.division.city'),
                            value: 'city',
                        });
                    }

                    if (!divs.neighbourhood || divs.neighbourhood === '' || divs.neighbourhood === null) {
                        this.options.push({
                            label: this.$t('general.division.neighbourhood'),
                            value: 'neighbourhood',
                        });
                    }
                }
            },

            /**
             * Make Divisions model
             */
            divisionsModel() {
                const divs = Object.keys(this.divs);
                divs.forEach((div) => {
                    if (this.divs[div] && this.divs[div].length) {
                        this.model.divisions_from[div] = null;
                        Object.keys(this.divs[div]).forEach((key) => {
                            this.$set(this.model.divisions_from, `${div}`, this.divs[div][key].value);
                        });
                    }
                });
            },
        },
    };
</script>

<template>
    <y-modal
        ref="modal"
        size="xs"
        @close="reset"
    >
        <template slot="header">
            <div class="title">
                {{ $t(`permissions.${type}.title`) }}
            </div>
        </template>

        <template slot="body">
            <p>{{ $t(`permissions.${type}.message`) }}</p>
        </template>

        <template slot="footer">
            <div class="ta-l w-100">
                <y-button
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
                <y-button
                    color="red"
                    loading-on="submitting-form"
                    @click.prevent.native="submit(action)"
                >
                    {{ $t(`button.${type}`) }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>

    import FormMixin from '@/mixins/Form';

    import { YModal } from '@deps';

    export default {
        name: 'BlockModal',

        components: {
            YModal,
        },

        mixins: [FormMixin],

        props: {
            /**
             * Roles array
             */
            roles: Array,

            /**
             * Organizations array
             */
            organizations: Array,
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                id  : null,
                type: null,
            };
        },

        methods: {
            /**
             * Open delete modal
             *
             * @param id
             * @param type
             */
            open(id, type = 'unblock') {
                this.id = id;
                this.type = type;
                this.$refs.modal.open();
            },

            /**
             * Close delete modal
             */
            close() {
                this.$refs.modal.close();
                this.reset();
            },

            /**
             * Reset delete modal
             */
            reset() {
                this.id = null;
                this.type = null;
            },

            /**
             * Assign role
             */
            action() {
                return this.$services.Person.userBlock({
                    id  : this.id,
                    type: this.type,
                }).then(() => {
                    this.$toast.success(this.$t(`messages.${this.type}.success`));
                    this.close();
                    this.$emit('refresh');
                }).catch((error) => this.handleError(error));
            },
        },

    };
</script>

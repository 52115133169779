<template>
    <y-loading
        class="persons-roles-fields"
        :active="$wait.is('loading-page')"
        height="calc( 100vh - 121px )"
    >
        <y-page-head
            :title="$t('roles.fields.title', [model.role])"
            icon="md-file-outline"
        >
            <template slot="actions">
                <y-button
                    id="refresh-button"
                    type="button"
                    icon="md-refresh"
                    @click.prevent="reFetch"
                />

                <!-- Save -->
                <y-button
                    color="blue"
                    form="main-form"
                    loading-on="submitting-form"
                    @click.prevent="submit(save)"
                >
                    {{ $t('button.save') }}
                </y-button>

                <!-- Setting -->
                <y-button @click.prevent.native="$refs.roleSettingsModal.open()">
                    {{ $t('roles.fields.actions.settings') }}
                </y-button>
            </template>
        </y-page-head>

        <main>
            <div class="row mb5">
                <div class="col-sm-8 col-center p0">
                    <div class="d-f justify-content-between align-items-center">
                        <div>
                            <y-form-field
                                v-model="query"
                                type="text"
                                :placeholder="$t('fields.search')"
                                no-label
                                name="search"
                                class="mb0"
                            />
                        </div>

                        <div>
                            <y-form-field
                                v-model="groupFilter"
                                type="select"
                                no-label
                                allow-empty
                                :options="filterOptions"
                                class="mb0"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <form
                id="main-form"
                @submit.prevent="submit(save)"
            >
                <div class="row">
                    <div class="col-sm-8 col-center persons-roles-fields__wrapper panel">
                        <y-empty-state
                            v-if="query && fieldItems && !fieldItems.length"
                            no-image
                            height="100"
                            class="mt10 mb0"
                            :message="$t('roles.fields.settings.no_field')"
                        />

                        <template v-else>
                            <y-role-field
                                v-for="(item, index) in fieldItems"
                                :key="index"
                                v-model="model.items[item.slug]"
                                :item="item"
                                :title="item.title"
                            >
                                <template v-for="(form, i) in formFilter">
                                    <y-row
                                        v-if="model"
                                        :key="i"
                                        class="mb5 justify-start align-items-center"
                                    >
                                        <y-col sm="2">
                                            <span class="persons-roles-fields__form-title">{{ form.label }}</span>
                                        </y-col>
                                        <y-col v-if="model.items[item.slug]">
                                            <div class="d-f justify-start align-items-center">
                                                <y-form-field
                                                    v-model="model.items[item.slug].involved[form.value]"
                                                    type="checkbox"
                                                    :label="$t('fields.roles.display')"
                                                    class="mb0 ml5"
                                                />

                                                <y-form-field
                                                    v-model="model.items[item.slug].required[form.value]"
                                                    type="checkbox"
                                                    :label="$t('fields.roles.required')"
                                                    class="mb0 ml5"
                                                />

                                                <y-form-field
                                                    v-model="model.items[item.slug].group[form.value]"
                                                    type="select"
                                                    no-label
                                                    :options="options"
                                                    :placeholder="$t('fields.roles.group')"
                                                    class="mb0 ml5"
                                                />

                                                <y-form-field
                                                    v-model="model.items[item.slug].order[form.value]"
                                                    type="number"
                                                    no-label
                                                    :placeholder="$t('fields.roles.order')"
                                                    class="mb0"
                                                />
                                            </div>
                                        </y-col>
                                    </y-row>
                                </template>
                            </y-role-field>
                        </template>
                    </div>
                </div>
            </form>
        </main>

        <y-role-settings-modal ref="roleSettingsModal" />
    </y-loading>
</template>

<script>
    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';

    import YFormField from '@deps/form/FormField';
    import YRoleField from '@/modules/persons/components/roles/Field';
    import YRoleSettingsModal from '@/modules/persons/components/roles/Settings';
    import YEmptyState from '@deps/EmptyState';

    export default {
        name: 'RolesEditor',

        components: {
            YFormField,
            YRoleField,
            YRoleSettingsModal,
            YEmptyState,
        },

        mixins: [
            PageMixin,
            FormMixin,
        ],

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.model ? this.$t('roles.fields.title_simple') : this.$t('roles.fields.title', [this.model.role]),
            };
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                model      : {},
                groups     : null,
                forms      : null,
                query      : null,
                groupFilter: null,
            };
        },

        computed: {
            /**
             * Check if its edit mode
             */
            isEdit() {
                return this.$route.params.slug;
            },

            /**
             * Retrun the group
             */
            options() {
                return this.groups ? this.groups : [];
            },

            /**
             * Return Filter Option
             */
            filterOptions() {
                return this.forms ? this.forms : [];
            },

            /**
             * Return the filed Items
             */
            fieldItems() {
                const result = [];
                if (this.query) {
                    Object.keys(this.model.items).forEach((key) => {
                        if (this.model.items[key].title.includes(this.query)) {
                            result.push(this.model.items[key]);
                        }
                    });
                }
                return this.query ? result : this.model.items;
            },

            /**
             * Form Filter
             */
            formFilter() {
                const result = [];
                if (this.groupFilter) {
                    Object.keys(this.forms).forEach((key) => {
                        if (this.forms[key].value === this.groupFilter) {
                            result.push(this.forms[key]);
                        }
                    });
                }
                return this.groupFilter ? result : this.forms;
            },
        },

        methods: {
            /**
             * Fetch fields
             */
            fetch() {
                const params = {
                    slug: this.$route.params.slug,
                };
                return this.$services.Person.profileFieldsForm(params).then((response) => {
                    this.model = response.data.results;
                    this.$set(this, 'groups', response.data.metadata.groups);
                    this.$set(this, 'forms', response.data.metadata.forms);
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Save the Fields
             */
            save() {
                const params = {
                    slug: this.$route.params.slug,
                    ...this.model,
                };
                return this.$services.Person.profileFieldsSave(params).then(() => {
                    this.$toast.success(this.$t('roles.fields.settings.messages.save.success'));
                }).catch((error) => {
                    this.handleError(error);
                });
            },
        },
    };
</script>

<template>
    <div>
        <y-page-head
            :title="title"
            icon="md-account-key"
        >
            <template slot="actions">
                <y-button
                    color="blue"
                    loading-on="submitting-form"
                    wide
                    :disabled="!unlocked.id && !unlocked.model"
                    @click.native="submit(save)"
                >
                    {{ $t('button.save') }}
                </y-button>
                <y-button @click="openCreateModal">
                    {{ $t('button.create') }}
                </y-button>
                <y-button
                    v-if="!hideImpersonate || isDeveloper"
                    class="color-red"
                    :disabled="isImpersonateMode"
                    loading-on="impersonate"
                    @click="doImpersonate"
                >
                    {{ $t('permissions.impersonate') }}
                </y-button>
            </template>
        </y-page-head>

        <y-loading
            class="panel-grid-layout"
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 239px )"
        >
            <main>
                <div
                    v-if="user && model"
                    class="rows"
                >
                    <y-panel
                        v-for="row in user.roles"
                        :key="`role-row-${row.id}`"
                        class="permission-row"
                        collapsible
                        :collapsed="isLocked(row.id)"
                    >
                        <template slot="header">
                            <div class="title">
                                <span>{{ $t('permissions.row.as') }}</span>
                                <span class="label gray">{{ model[row.id].role.title }}</span>

                                <span>{{ $t('permissions.row.in') }}</span>
                                <span class="label gray">{{ model[row.id].organization.name }}</span>

                                <span>{{ $t('permissions.row.status') }}</span>
                                <y-form-field
                                    v-model="model[row.id].level_id"
                                    type="select"
                                    :options="levelOptions(row.role.id)"
                                    label-field="title"
                                    value-field="id"
                                    no-label
                                    :disabled="isLocked(row.id)"
                                />

                                <span>{{ $t('permissions.row.has') }}</span>
                            </div>
                        </template>

                        <template slot="functions">
                            <div class="permission-block ml5">
                                <y-button
                                    v-if="row.block"
                                    :key="`unblock-${row.id}`"
                                    size="sm"
                                    class="color-green"
                                    :disabled="isLocked(row.id)"
                                    @click="openBlockModal(row.id, 'unblock')"
                                >
                                    {{ $t('button.unblock') }}
                                </y-button>
                                <y-button
                                    v-else
                                    :key="`block-${row.id}`"
                                    size="sm"
                                    class="color-orange"
                                    :disabled="isLocked(row.id)"
                                    @click="openBlockModal(row.id, 'block')"
                                >
                                    {{ $t('button.block') }}
                                </y-button>
                            </div>
                            <div class="permission-delete">
                                <y-button
                                    :key="`delete-${row.id}`"
                                    size="sm"
                                    class="color-red"
                                    :disabled="isLocked(row.id)"
                                    @click="openDeleteModal(row.id)"
                                >
                                    {{ $t('button.delete') }}
                                </y-button>
                            </div>
                            <div class="permission-lock">
                                <i class="icon md-lock-open" />
                                <y-form-field
                                    type="checkbox"
                                    switch
                                    circular
                                    no-label
                                    size="sm"
                                    :name="`lock-${row.id}`"
                                    color="green"
                                    :disabled="unlocked.id && !unlocked.permits"
                                    :value="unlocked.id === row.id"
                                    @click.native.prevent="unlocked.id && !unlocked.permits ? null : toggleLock(row.id)"
                                />
                                <i class="icon md-lock" />
                            </div>
                        </template>

                        <y-loading
                            class="panel-grid-layout"
                            :active="unlocked.id && !unlocked.permits"
                            height="200px"
                        >
                            <div class="parts">
                                <div
                                    v-for="(part, key) in unlocked.permits"
                                    :key="`row-part-${key}`"
                                    class="part"
                                >
                                    <div class="title">
                                        {{ part.title }}
                                    </div>
                                    <y-row
                                        v-for="(prow, rowId) in part.rows"
                                        :key="`permits-row-${rowId}`"
                                    >
                                        <y-col size="3">
                                            <div class="type">
                                                {{ prow.title }}
                                            </div>
                                        </y-col>
                                        <y-col size="9">
                                            <y-form-field
                                                v-for="permit in prow.permits"
                                                :key="`permit-${permit.slug}`"
                                                v-model="unlocked.model[permit.slug]"
                                                :name="permit.slug"
                                                :label="permit.title"
                                                type="checkbox"
                                            />
                                        </y-col>
                                    </y-row>
                                </div>
                            </div>
                        </y-loading>
                    </y-panel>
                </div>
            </main>
        </y-loading>

        <y-create-modal
            v-if="user"
            ref="addPermission"
            :roles="roles"
            :organizations="organizations"
            @refresh="reFetch"
        />

        <y-change-alert-modal
            v-if="unlocked.id"
            ref="changeAlert"
            @save="save"
            @cancel="target ? forceToggleLocked(target) : cancelSave"
        />

        <y-delete-modal
            v-if="user"
            ref="deletePermission"
            @refresh="reFetch"
        />

        <y-block-modal
            v-if="user"
            ref="blockPermission"
            @refresh="reFetch"
        />
    </div>
</template>

<script>

    import PageMixin from '@/mixins/Page';
    import FormMixin from '@/mixins/Form';

    import { YFormField } from '@deps';

    import YCreateModal from '@/modules/persons/components/permissions/CreateModal';
    import YChangeAlertModal from '@/modules/persons/components/permissions/ChangeAlertModal';
    import YDeleteModal from '@/modules/persons/components/permissions/DeleteModal';
    import YBlockModal from '@/modules/persons/components/permissions/BlockModal';

    export default {

        name: 'PersonsRolePermission',

        components: {
            YFormField,
            YCreateModal,
            YChangeAlertModal,
            YDeleteModal,
            YBlockModal,
        },

        mixins: [PageMixin, FormMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc('roles-list', this.$t('breadcrumbs.persons.roles')),
                this.$bc(this.$t('breadcrumbs.persons.permissions')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.title,
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                roles          : null,
                organizations  : null,
                user           : null,
                hideImpersonate: true,

                model: null,

                target: null,

                unlocked: {
                    id           : null,
                    permits      : null,
                    originalModel: null,
                    model        : null,
                },
            };
        },

        computed: {
            /**
             * Return page title
             */
            title() {
                return this.user
                    ? `${this.$t('permissions.title')} ${this.user.name}`
                    : this.$t('permissions.title');
            },

            /**
             * Check if loaded permits are changed
             */
            isPermitsChanged() {
                return JSON.stringify(this.unlocked.originalModel) !== JSON.stringify(this.unlocked.model);
            },

            /**
             * Check if this is in impersonate mode
             */
            isImpersonateMode() {
                return !!localStorage.getItem('admin_token');
            },
        },

        methods: {

            /**
             * Return level options
             *
             * @param id
             */
            levelOptions(id) {
                if (!this.roles) {
                    return [];
                }
                const item = this.roles.find((role) => role.id === id);
                return item ? item.levels : [];
            },

            /**
             * Open create modal
             */
            openCreateModal() {
                this.$refs.addPermission.open();
            },

            /**
             * Open delete modal
             *
             * @param id
             */
            openDeleteModal(id) {
                this.$refs.deletePermission.open(id);
            },

            /**
             * Open block modal
             *
             * @param id
             * @param type
             */
            openBlockModal(id, type) {
                this.$refs.blockPermission.open(id, type);
            },

            /**
             * Fetch roles
             */
            fetch() {
                this.unlocked = {
                    id           : null,
                    permits      : null,
                    originalModel: null,
                    model        : null,
                };
                const params = {
                    id: this.$route.params.id,
                };
                return this.$services.Person.userPanelRoles(params).then((response) => {
                    this.roles = response.data.results.general.roles;
                    this.organizations = response.data.results.general.organizations;
                    this.user = response.data.results.user;
                    this.impersonateButton();

                    // Make locks and model
                    this.model = {};
                    this.user.roles.forEach((item) => {
                        // Make model
                        this.model[item.id] = {
                            role        : item.role,
                            organization: item.organization,
                            level_id    : item.level_id,
                        };
                    });
                }).catch((error) => this.handleError(error)).finally(() => {
                    if (this.target) {
                        this.forceToggleLocked(this.target);
                    }
                });
            },

            /**
             * Unlock row and fetch permits
             *
             * @param id
             */
            toggleLock(id) {
                if (!this.unlocked.id) {
                    this.fetchPermits(id);
                } else if (this.isPermitsChanged) {
                    this.target = id;
                    this.$refs.changeAlert.open();
                } else if (this.unlocked.id === id) {
                    this.cancelSave();
                } else {
                    this.fetchPermits(id);
                }
            },

            /**
             * Unlock row and fetch permits by force
             *
             * @param id
             */
            forceToggleLocked(id) {
                this.cancelSave();
                this.fetchPermits(id);
            },

            /**
             * Fetch permits
             *
             * @param id
             */
            fetchPermits(id) {
                this.target = null;
                this.$set(this, 'unlocked', {
                    id,
                    permits      : null,
                    originalModel: {},
                    model        : {
                        id,
                        level_id: null,
                    },
                });
                this.$services.Person.userPanelPermits({ id }).then((response) => {
                    this.unlocked.permits = response.data.results;

                    // Results is empty
                    if (Array.isArray(this.unlocked.permits)) {
                        return false;
                    }

                    Object.values(this.unlocked.permits).forEach((resultRow) => {
                        Object.values(resultRow.rows).forEach((row) => {
                            row.permits.forEach((permit) => {
                                this.$set(this.unlocked.model, permit.slug, permit.checked ? 1 : 0);
                            });
                        });
                    });

                    this.$set(this.unlocked, 'originalModel', { ...this.unlocked.model });

                    return true;
                }).catch((error) => {
                    this.handleError(error);
                    this.cancelSave();
                });
            },

            /**
             * Save permits
             */
            save() {
                this.unlocked.model.level_id = this.model[this.unlocked.id].level_id;
                return this.$services.Person.userPanelPermitsSave(this.unlocked.model).then(() => {
                    this.$toast.success(this.$t('messages.permitSave.success'));
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    if (this.target) {
                        this.forceToggleLocked(this.target);
                    } else {
                        this.cancelSave();
                    }
                });
            },

            /**
             * Cancel save changes
             */
            cancelSave() {
                this.target = null;
                this.unlocked = {
                    id           : null,
                    target       : null,
                    permits      : null,
                    originalModel: null,
                    model        : null,
                };
            },

            /**
             * Check if row is unlocked
             *
             * @param id
             */
            isLocked(id) {
                return this.unlocked.id !== id;
            },

            /**
             * Impersonate User
             */
            doImpersonate() {
                this.$wait.start('impersonate');
                localStorage.setItem('admin_token', localStorage.getItem('token'));
                localStorage.setItem('impersonate_route_name', this.$route.name);
                const routeParams = this.$route.params;
                localStorage.setItem('impersonate_route_params', JSON.stringify(routeParams));

                const params = {
                    id: this.$route.params.id,
                };
                return this.$services.Person.impersonate(params).then((response) => {
                    localStorage.setItem('token', response.data.results.access_token);
                    this.$router.push({ name: 'dashboard' });
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('impersonate');
                });
            },

            /**
             * Check to show impersonate
             */
            impersonateButton() {
                let stop = false;
                if (this.user && !stop) {
                    this.user.roles.forEach((i) => {
                        if (i.role.slug === 'manager') {
                            this.hideImpersonate = true;
                            stop = true;
                        } else if (i.role.slug === 'super') {
                            this.hideImpersonate = true;
                            stop = true;
                        } else {
                            this.hideImpersonate = false;
                        }
                    });
                }
            },

        },

    };

</script>

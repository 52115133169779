<template>
    <y-panel :title="title">
        <template slot="functions">
            <form @submit.prevent="fetch">
                <div class="d-f">
                    <y-form-field
                        v-model="model.is_unique"
                        type="checkbox"
                        name="is_unique"
                        :label="$t('fields.roles.is_unique')"
                        size="sm"
                        class="mb0"
                        switch
                        circular
                        color="green"
                    />
                </div>
            </form>
        </template>

        <slot />
    </y-panel>
</template>

<script>
    import YFormField from '@deps/form/FormField';

    export default {
        name: 'RoleField',

        components: {
            YFormField,
        },

        /**
         * Watch value set new model
         */
        model: {
            // eslint-disable-next-line require-jsdoc
            handler() {
                this.$emit('input', this.model);
            },
            deep: true,
        },

        props: {
            /**
             * Title of the field
             */
            title: {
                type   : String,
                default: 'Field',
            },

            /**
             * Item Data
             */
            item: [Array, Object],
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                model: {
                    is_unique: false,
                },
            };
        },

        watch: {
            /**
             * Watch model and emit on change
             */
            model: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },
        
        /**
         * @inheritdoc
         */
        created() {
            if (this.item) {
                this.$set(this, 'model', this.item);
            }
        },
    };
</script>
